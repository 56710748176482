<template>
  <div>
    <div class="position-relative">
      <label for="search-input" class="search-icon">
        <i  class="far fa-search"></i>
      </label>
      <input
        id="search-input"
        type="text"
        class="form-control search-text ps-4"
        :placeholder="$t(placeholder)"
        :value="modelValue"
        @input="inputCaptured"
        ref="searchInput"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';

export default {
  name: 'HMSearch',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'GENERAL.PLACEHOLDER.SEARCH',
    },
    delay: {
      type: Number,
      default: 400,
    },
  },
  computed: {
    inputCaptured() {
      return debounce(this.onInput, this.delay);
    },
  },
  methods: {
    onInput($event) {
      this.$emit('update:modelValue', $event.target.value);
    },
  },
  mounted() {
    const input = this.$refs.searchInput;
    this.$emit('initialized', input);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.search-icon {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  color: var(--brand-color, $coral);
  margin-left: 0.5rem;
}
.search-text {
  &::placeholder {
    font-style: normal;
  }
}
</style>
